<script>
	import Modal from './Modal.svelte';
	export let showToast = false;
	export let css = '';
	export let onClose = () => {};
</script>

<Modal
	bind:show={showToast}
	options={{
		from: 'bottom',
		topLevel: true,
		cssText: `bottom: 1rem;
top: unset;
--tw-translate-y: 0;max-width: min(26rem, 95vw);${css || ''}`
	}}
	on:close={() => {
		onClose();
	}}
>
	<slot />
</Modal>

<style>
	/* your styles go here */
</style>
