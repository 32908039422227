<script>
	/* Display errors. If errors is a list, displays all of them.
	 */
	import { goto } from '$app/navigation';

	import { dataRequirements } from '$lib/graphLists.js';
	export let errors;
	export let brokenThing;

	function formatError(error) {
		if (typeof error === 'string' || error instanceof String) {
			if (error.includes('Firebase')) {
				error = 'Error: ' + error.replace(/.*\/|-|\)./g, ' ').trim();
			}
			return error;
		} else {
			error = (error || {}).message || JSON.stringify((error || {}).data || error);
			if (error.includes('Firebase')) {
				error = 'Error: ' + error.replace(/.*\/|-|\)./g, ' ').trim();
			}
			return error;
		}
	}
</script>

{#if errors && errors?.length > 0}
	{#if Array.isArray(errors)}
		{#each errors as error}
			<p class="error text-red-400 text-center capitalize">{formatError(error)}</p>
		{/each}
	{:else}
		<p class="error text-red-400 text-center capitalize">{formatError(errors)}</p>
	{/if}
	{#if dataRequirements[brokenThing]}
		<p class="text-sm">
			<a
				on:click|preventDefault={() => goto('profile')}
				href="/profile/data-choices"
				target="_blank"
			>
				Check your data choices
			</a>
			or upload:
		</p>
		<ul class="text-sm mb-1 text-blue-300">
			{#each dataRequirements[brokenThing] as req}
				<li class="list-disc ml-5" style="max-width: calc(100% - 1.25rem)">{req}</li>
			{/each}
		</ul>
	{/if}
{/if}
